/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-02-05T14:59:22.092Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.1.10200-1711016';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
